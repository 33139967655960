/**
 * 自定义覆盖物所在位置图标
 */
class LocationDom extends window.TMap.DOMOverlay {
  constructor(options) {
    super(options);
  }

  // 初始化：获取配置参数
  onInit({ position, radius = 16 } = {}) {
    Object.assign(this, {
      position,
      radius,
    });
  }

  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick);
    }
  }

  createDOM() {
    let div = document.createElement("div");
    div.style.cssText = `position:absolute;top:0px;left:0px;width:${this.radius}px;height:${this.radius}px;background:#0057FF;box-shadow: 0px 4px 6px 0px rgba(0,87,255,0.25);border: 2px solid #FFFFFF;border-radius: 50%`;
    // click事件监听
    this.onClick = () => {
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit("click");
    };
    // pc端注册click事件，移动端注册touchend事件
    div.addEventListener("click", this.onClick);
    console.log("createDOM");
    return div;
  }

  updateDOM() {
    if (!this.map) {
      return;
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position);
    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + "px";
    let top = pixel.getY() - this.dom.clientHeight / 2 + "px";
    this.dom.style.transform = `translate(${left}, ${top})`;
    console.log("updateDOM");
  }
}

/**
 * 自定义聚合点气泡
 */
class ClusterBubbleDom extends window.TMap.DOMOverlay {
  constructor(options) {
    super(options);
  }

  // 初始化：获取配置参数
  onInit(options) {
    this.content = options.content;
    this.position = options.position;
  }

  onDestroy() {
    this.dom.removeEventListener("touchend", this.onTouchend);
    this.removeAllListeners();
  }

  onTouchend() {
    this.emit("touchend");
  }

  createDOM() {
    const domWrap = document.createElement("div");
    const numDom = document.createElement("div");
    const labelDom = document.createElement("div");
    domWrap.classList.add("label-wrapper");
    numDom.classList.add("num");
    labelDom.classList.add("label");
    domWrap.style.cssText = [
      "position: absolute;",
      "top: 0;",
      "left: 0;",
      "display: flex;",
      "align-items: center;",
      "justify-content: center;",
    ].join(" ");
    numDom.style.cssText = [
      "width: " + (20 + parseInt(this.content.num) / 30) + "px;",
      "height: " + (20 + parseInt(this.content.num) / 30) + "px;",
      "line-height: " + (20 + parseInt(this.content.num) / 30) + "px;",
      "border-radius: 50%;",
      "color: #625a5a;",
      "font-weight: 500;",
      "text-align: center;",
      "opacity: 0.88;",
      "background-image: linear-gradient(139deg, #fff 0%, #f0ebe1 100%);",
      "box-shadow: 0 1px 3px 0 rgb(104 21 21 / 65%);",
      "font-size: 0.2rem;",
    ].join(" ");
    // "color: #fff;",
    // "background-image: linear-gradient(139deg, #4294ff 0%, #295bff 100%);",
    // "box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);",
    labelDom.style.cssText = [
      "margin-left: 0.05rem;",
      "color: #616b81;",
      "font-size: 0.2rem;",
    ].join(" ");

    domWrap.appendChild(numDom);
    domWrap.appendChild(labelDom);
    numDom.innerText = this.content.num;
    labelDom.innerText = this.content.label;

    // 监听点击事件，实现zoomOntouchend
    this.onTouchend = this.onTouchend.bind(this);
    // pc端注册touchend事件，移动端注册touchend事件
    domWrap.addEventListener("touchend", this.onTouchend);
    return domWrap;
  }

  updateDOM() {
    if (!this.map) {
      return;
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position);

    // 使文本框中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + "px";
    let top = pixel.getY() - this.dom.clientHeight / 2 + "px";
    this.dom.style.transform = `translate(${left}, ${top})`;

    this.emit("dom_updated");
  }
}

export { LocationDom, ClusterBubbleDom };
